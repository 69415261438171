@font-face {
  font-family: 'iCiel Panton Bold';
  src: url('../../assets/fonts/iCielPanton/iCielPanton-Black.otf')
      format('truetype'),
    url('../../assets/fonts/iCielPanton/iCielPanton-BlackItalic.otf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'iCiel Panton Light';
  src: url('../../assets/fonts/iCielPanton/iCielPanton-Light.otf')
      format('truetype'),
    url('../../assets/fonts/iCielPanton/iCielPanton-LightItalic.otf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'UTM Avo Bold';
  src: url('../../assets//fonts/UTM-Avo/UTM\ AvoBold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'UTM Avo';
  src: url('../../assets//fonts/UTM-Avo/UTM\ Avo.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
