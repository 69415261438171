.header-layout {
  @include pxRem(height, 80);
  @include pxRem(padding, 0 40 0 70);
  overflow: auto;
  line-height: initial;
  background-color: map-get($colors, header-bg);

  @include sp {
    @include pxRem(padding, 0 20);
  }

  .header-left {
    display: flex;
    flex: 1;
    .system-logo {
      @include sp {
        pointer-events: none;
      }
      img {
        @include sp {
          @include pxRem(width, 85);
        }
      }
    }
  }
  .header-right {
    .target-progress-content,
    .btn-reset-target,
    .text-target-cards {
      @include sp {
        display: none;
      }
    }
  }
  .input-search {
    @include pxRem(max-width, 320);
    @include pxRem(padding, 8 20);
    @include pxRem(margin-left, 100);
    @include sp {
      display: none;
    }
    .ant-input {
      @include pxRem(margin-left, 16);
      padding: 0;
      background-color: map-get($colors, white);
      border-radius: 0;
    }
  }
  .user-info {
    @include pxRem(width, 240);
    @include pxRem(padding, 4);
    @include pxRem(padding-right, 30);
    @include pxRem(border-radius, 12);
    position: relative;
    border: 1px solid map-get($colors, primary);
    background-color: map-get($colors, white);
    @include sp {
      @include pxRem(width, 190);
      @include pxRem(padding-right, 24);
      margin-left: 0;
    }
    .user-avatar {
      @include pxRem(border-radius, 8);
      @include sp {
        @include pxRem(width, 30 !important);
        @include pxRem(height, 30 !important);
      }
    }
    .user-name {
      @include line-clamp(2);
      flex: 1;
      color: map-get($colors, primary);
      @include sp {
        @include pxRem(font-size, 14);
        @include pxRem(margin-left, 8);
      }
    }
    .arrow-down-icon {
      @include pxRem(right, 16);
      position: absolute;
      @include sp {
        @include pxRem(right, 6);
      }
    }
  }
  .target-progress-content {
    @include pxRem(padding, 5);
    @include pxRem(width, 250);
    @include pxRem(border-radius, 30);
    background-color: map-get($colors, white);
    .ant-progress-outer {
      padding: 0;
      .ant-progress-inner,
      .ant-progress-bg {
        @include pxRem(border-radius, 30);
      }
      .ant-progress-bg {
        @include pxRem(height, 35 !important);
      }
    }
    .ant-progress-text {
      width: auto;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      color: map-get($colors, target-progress-text);
      margin-left: 0px;
    }
  }
  .btn-reset-target {
    @include pxRem(font-size, map-get($font-sizes, sm));
    @include pxRem(padding, 6 25);
  }
}
