.page-content-header-wrapper {
  background-color: #f0f3f7;
  position: relative;
  .ant-page-header {
    @include pxRem(padding, 12 0);
    @include sp {
      @include pxRem(padding, 30 40);
    }
    .ant-page-header-heading {
      @include pxRem(padding-left, 110);
      font-family: $font-family-utm-avo-bold;
      .ant-page-header-heading-left {
        @include sp {
          display: none;
        }
        .ant-page-header-back {
          @include pxRem(margin-right, 25);
        }
        .ant-page-header-heading-title {
          @include pxRem(font-size, 24px);
          @include pxRem(line-height, 28px);
          color: map-get($colors, text-blue);
        }
      }
    }
  }
  .page-content-header-title {
    @include pxRem(font-size, map-get($font-sizes, xl));
    @include pxRem(line-height, 26);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: map-get($colors, text-blue);
    @include sp {
      @include pxRem(font-size, map-get($font-sizes, lg));
    }
  }
  .qa-questions-result {
    @include pxRem(right, 150);
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    .result-ticker {
      position: relative;
      z-index: 2;
    }
    .result-panel {
      @include pxRem(top, 20);
      @include pxRem(padding, 20 25);
      @include pxRem(border-radius, 4);
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      border: 2px solid map-get($colors, orange);
    }
  }
}
