.ant-message {
  @include sp {
    @include pxRem(top, 50 !important);
  }
  .ant-message-notice {
    text-align: right;
    padding: 0 !important;
    @include sp {
      text-align: center;
    }
    .ant-message-notice-content {
      @include pxRem(padding, 18 30);
      @include pxRem(max-width, 400);
      @include pxRem(border-radius, 10 0 0 10);
      box-shadow: none;
      text-align: left;
      @include sp {
        @include pxRem(padding, 15);
        @include pxRem(border-radius, 10);
        max-width: calc(100% - #{convertPxToRem(40)});
      }
      .ant-message-custom-content {
        display: flex;
        align-items: center;
        .text-message {
          @include sp {
            @include pxRem(font-size, 16);
          }
        }
      }
    }
    &.success-message {
      .ant-message-notice-content {
        background-color: map-get($colors, category-bg);
      }
    }
    &.error-message {
      .ant-message-notice-content {
        background-color: map-get($colors, msg-error);
      }
    }
  }
  .anticon {
    @include pxRem(margin-right, 16);
  }
}
