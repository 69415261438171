.ant-form {
  .ant-form-item-explain {
    @include pxRem(margin, 4 0 8);
    text-align: left;
  }
  .ant-form-item-label > label {
    @include pxRem(font-size, map-get($font-sizes, md));
    color: map-get($colors, text-gray);
    @include sp {
      @include pxRem(font-size, map-get($font-sizes, sm));
    }
  }
  .ant-form-item-required {
    &::before {
      @include pxRem(margin-left, 4);
      order: 2;
      margin-right: 0;
    }
  }
  &.payment-form {
    .ant-input,
    .ant-picker {
      background-color: map-get($colors, white);
      border: 1px solid map-get($colors, text-gray);
    }
  }
}
