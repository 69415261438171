.ant-popover {
  padding: 0;
  .ant-popover-inner {
    @include pxRem(border-radius, 10);
    .ant-popover-inner-content {
      @include pxRem(padding, 10);
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  &.profile-popover {
    @include pxRem(width, 240);
    @include sp {
      @include pxRem(width, 190);
    }
    .ant-list-item {
      padding: 0;
      border: none;
    }
    .profile-menu-item {
      @include pxRem(padding, 10);
      @include pxRem(border-radius, 6);
      width: 100%;
      display: flex;
      align-items: center;
      @include sp {
        padding: 0;
      }
      .anticon {
        @include sp {
          @include pxRem(font-size, 20);
        }
      }
      .text-menu-item {
        @include sp {
          @include pxRem(font-size, 14);
        }
      }
      &:hover {
        background-color: map-get($colors, category-bg);
      }
    }
  }
}
