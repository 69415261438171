.ant-select {
  @include pxRem(border-radius, 12);
  @include pxRem(font-size, map-get($font-sizes, md));
  color: map-get($colors, text-blue);
  background-color: map-get($colors, input-bg);
  .ant-select-selector {
    &:not(.ant-select-customize-input) {
      @include pxRem(padding, 10 20);
      border: none;
      height: auto;
    }
    .ant-select-selection-placeholder {
      color: map-get($colors, text-gray);
    }
    .ant-select-selection-item {
      @include pxRem(line-height, 30);
    }
    .ant-select-selection-search {
      @include pxRem(left, 20);
      .ant-select-selection-search-input {
        height: 100%;
      }
    }
  }
  .ant-select-arrow {
    @include pxRem(right, 20);
    margin-top: 0;
    width: auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  &.ant-select-disabled {
    background-color: map-get($colors, input-disabled);
    .ant-select-selection-item {
      color: map-get($colors, text-blue);
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option {
    @include pxRem(padding, 14 20);
    @include pxRem(font-size, map-get($font-sizes, lg));
    color: map-get($colors, text-blue);
    &.ant-select-item-option-selected {
      background-color: map-get($colors, input-bg);
    }
  }
  &.university-dropdown {
    .ant-select-item-option-content {
      white-space: initial;
    }
  }
}
