.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.d-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.self-align-start {
  align-self: flex-start;
}

.desktop-only {
  @include sp {
    display: none !important;
  }
}

.mobile-only {
  @include pc {
    display: none !important;
  }
}
