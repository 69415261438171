.ant-modal {
  padding: 0;
  .ant-modal-close-x {
    @include pxRem(top, 15);
    @include pxRem(right, 15);
    width: auto;
    height: auto;
    position: absolute;
    @include sp {
      @include pxRem(top, 4);
      @include pxRem(right, 15);
    }
    .anticon {
      @include sp {
        @include pxRem(font-size, 35);
      }
    }
  }
  .ant-modal-header {
    @include pxRem(height, 70);
    @include pxRem(border-radius, 14 14 0 0);
    @include sp {
      @include pxRem(border-radius, 6 6 0 0);
    }
  }
  .ant-modal-content {
    @include pxRem(border-radius, 14);
    background-color: map-get($colors, sidebar-bg);
    @include sp {
      @include pxRem(border-radius, 6);
    }
  }

  &.target-modal {
    .ant-modal-body {
      @include pxRem(padding, 40 60);
      .taget-nurse-img {
        @include pxRem(margin-bottom, -40);
      }
      .ant-btn-block {
        @include pxRem(padding, 10 60);
      }
      .target-quote {
        @include pxRem(border-radius, 20);
        @include pxRem(padding, 20 15);
        @include pxRem(font-size, map-get($font-sizes, lg));
        position: relative;
        color: map-get($colors, text-blue);
        background-color: map-get($colors, category-bg);
        &::after {
          @include pxRem(bottom, -23);
          @include pxRem(right, 50);
          content: '';
          position: absolute;
          display: inline-block;
          border: #{convertPxToRem(12)} solid map-get($colors, category-bg);
          border-left-color: transparent;
          border-bottom-color: transparent;
          border-radius: 0px 0px #{convertPxToRem(4)} 0px;
        }
      }
    }
  }

  &.purchase-modal,
  &.shipping-info-modal {
    @include sp {
      max-width: 100%;
      margin: 0;
      padding: 0;
      height: 100vh;
    }
    .ant-modal-content {
      @include sp {
        height: 100%;
        background-color: map-get($colors, white);
        display: flex;
        flex-direction: column;
      }
    }
    .ant-modal-header {
      text-align: center;
      background-color: map-get($colors, category-bg);
      @include sp {
        @include pxRem(padding, 13 60);
        height: auto;
      }
      .ant-modal-title {
        @include pxRem(font-size, map-get($font-sizes, xxl));
        font-family: $font-family-utm-avo-bold;
        line-height: inherit;
        color: map-get($colors, text-blue);
        @include sp {
          @include pxRem(font-size, 16);
          @include line-clamp(2);
          line-height: initial;
        }
        @include small-sp {
          @include pxRem(font-size, 14);
        }
      }
    }
    .ant-modal-body {
      @include pxRem(padding, 25 40);
      @include pxRem(border-bottom-left-radius, 14);
      @include pxRem(border-bottom-right-radius, 14);
      background-color: map-get($colors, white);
      @include sp {
        @include pxRem(padding, 0 20 20);
        overflow: auto;
        background-color: map-get($colors, white);
        flex: 1 0 0;
      }
      .purchase-info-mobile {
        @include sp {
          @include pxRem(padding, 8);
          @include pxRem(border-radius, 10);
          @include pxRem(margin-bottom, 20);
          text-align: center;
          background-color: map-get($colors, category-bg);
        }
      }
      .category-card-preview {
        @include sp {
          flex-direction: column;
          align-items: center;
        }
        .category-card {
          @include sp {
            @include pxRem(width, 180);
            padding: 0 !important;
            max-width: unset;
          }
          &:not(:first-child) {
            @include sp {
              @include pxRem(margin-top, 10);
            }
          }
        }
      }
      .btn-buy {
        @include sp {
          @include pxRem(font-size, 18);
          @include pxRem(padding, 8 0);
          width: 100%;
        }
      }
      .category-image-list {
        @include pxRem(margin, 16 -8 0);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include sp {
          @include pxRem(margin, 30 -15 0);
          @include pxRem(padding, 0 50);
        }
        .category-image {
          @include pxRem(padding, 0 8);
          @include pxRem(margin-bottom, 16);
          flex: 0 0 calc(100% / 7);
          @include sp {
            @include pxRem(padding, 0 15);
            flex: 0 0 calc(100% / 2);
          }
        }
      }
      .category-name-list {
        @include pxRem(gap, 6 12);
        @include pxRem(font-size, map-get($font-sizes, sm));
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include sp {
          grid-template-columns: 1fr;
          @include pxRem(padding-left, 50);
        }
      }
      .card-number {
        &::before,
        &::after {
          content: '';
          height: 1px;
          width: 100%;
          display: inline-block;
          border-top: 1px solid map-get($colors, border);
        }
      }
    }
  }

  &.purchase-modal {
    .ant-modal-content {
      @include sp {
        border-radius: 0;
      }
    }
    .ant-modal-header {
      @include sp {
        border: none;
        background-color: map-get($colors, white);
      }
    }
  }

  &.app-version-modal,
  &.shipping-info-modal,
  &.payment-confirm-modal {
    @include sp {
      max-width: calc(100% - #{convertPxToRem(40)});
      width: 100%;
    }
  }

  &.shipping-info-modal {
    @include sp {
      height: 85%;
    }
    .ant-modal-body {
      @include sp {
        @include pxRem(padding, 20);
      }
      .ant-form-item {
        @include sp {
          @include pxRem(margin-bottom, 10);
        }
      }
      .btn-shipping-info {
        @include sp {
          @include pxRem(padding, 10 0);
          width: 100%;
        }
      }
    }
  }

  &.payment-confirm-modal {
    .ant-modal-header {
      @include pxRem(padding, 20);
      background-color: map-get($colors, sidebar-bg);
      height: auto;
      border: none;
      .ant-modal-title {
        text-align: center;
        color: map-get($colors, primary);
        font-family: 'UTM Avo Bold';
        @include pc {
          @include pxRem(font-size, 18);
        }
      }
    }
    .ant-modal-body {
      @include pxRem(padding, 0 20 35);
      @include sp {
        @include pxRem(padding, 0 20 28);
      }
      .object-info,
      .shipping-info {
        @include pxRem(padding, 15 20);
        @include pxRem(border-radius, 10);
        background-color: map-get($colors, white);
        @include sp {
          @include pxRem(padding, 15);
        }
      }
      .object-info {
        .separate-line {
          @include pxRem(height, 2);
          @include pxRem(margin, 12 0);
          background-color: #a6cfc9;
        }
      }
      .shipping-info {
        @include pxRem(margin-top, 16);
        .shipping-info-content {
          display: flex;
          @include pc {
            flex-wrap: wrap;
          }
          @include sp {
            flex-direction: column;
          }
          .shipping-info-item {
            display: flex;
            .anticon {
              @include pxRem(margin-top, 2);
            }
            &:not(:first-child) {
              @include sp {
                @include pxRem(margin-top, 8);
              }
            }
            @include sp {
              margin: 0;
            }
          }
        }
      }
      .actions-area {
        display: flex;
        @include pxRem(margin-top, 28);
        @include sp {
          @include pxRem(margin-top, 24);
        }
        @include pc {
          @include pxRem(padding, 0 54);
        }
        .ant-btn {
          padding-left: 0;
          padding-right: 0;
          flex: 1 0 0;
          @include sp {
            @include pxRem(font-size, 14);
          }
        }
      }
    }
  }

  &.category-finish-modal,
  &.purchase-trial-modal {
    .ant-modal-body {
      @include pxRem(padding, 38);
      .btn-group {
        .ant-btn {
          @include pxRem(padding, 10);
          flex: 1;
        }
      }
    }
  }
}
