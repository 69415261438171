.feedback-page {
  .page-content {
    &.has-page-title {
      padding-top: 15px !important;
    }
  }
  .feedback-action-wrapper {
    @include pxRem(max-width, 390);
    @include pxRem(border-radius, 10);
    @include pxRem(padding, 40);
    box-shadow: 0px 8px 30px rgba(138, 149, 158, 0.2);
    .feedback-action-text {
      @include pxRem(font-size, map-get($font-sizes, lg));
      @include pxRem(line-height, 26);
    }
    .feedback-action-icon {
      @include pxRem(left, 45);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .feedback-action-button {
      @include pxRem(height, 54);
      position: relative;
    }
  }
}
