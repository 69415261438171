.sidebar-layout {
  .sidebar-menu {
    @include pxRem(padding, 50 0 0 24);
    height: 100%;
    border: none;
    overflow: auto;
    background-color: map-get($colors, sidebar-bg);
    .menu-item {
      @include pxRem(padding, 6);
      @include pxRem(border-radius, 40 0 0 40);
      @include pxRem(margin, 0 0 15);
      color: map-get($colors, text-gray);
      line-height: initial;
      height: auto;
      .menu-link {
        color: map-get($colors, text-gray);
      }
      .menu-icon {
        @include pxRem(font-size, 24);
        @include pxRem(padding, 7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
      }
      &.ant-menu-item-selected {
        background-color: map-get($colors, primary);
        .menu-link {
          color: map-get($colors, white);
        }
        .menu-icon {
          path {
            fill: map-get($colors, primary);
          }
          background-color: map-get($colors, white);
        }
      }
      &:not(.ant-menu-item-selected):hover {
        background-color: map-get($colors, header-bg);
      }
    }
    &.ant-menu-inline {
      .ant-menu-item {
        @include pxRem(padding-left, 6 !important);
        &::after {
          border: none;
        }
      }
    }
  }
  @include sp {
    display: none;
  }
}
