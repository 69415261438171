.ant-picker {
  @include pxRem(border-radius, 12);
  @include pxRem(padding, 10 20);
  width: 100%;
  border: none;
  background-color: map-get($colors, input-bg);
  .ant-picker-input > input {
    @include pxRem(font-size, map-get($font-sizes, md));
    @include pxRem(line-height, 30);
    color: map-get($colors, text-blue);
    &::placeholder {
      color: map-get($colors, text-gray);
    }
  }
  &.center-text {
    .ant-picker-input > input {
      text-align: center;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-content {
    tr {
      th {
        color: map-get($colors, black);
      }
    }
  }
  .ant-picker-header-view {
    .ant-picker-month-btn,
    .ant-picker-year-btn {
      color: map-get($colors, text-blue);
    }
  }
  .ant-picker-cell {
    color: map-get($colors, text-blue);
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background-color: map-get($colors, header-bg);
      }
    }
    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border: 1px solid map-get($colors, primary);
        }
      }
    }
  }
  .ant-picker-today-btn {
    color: map-get($colors, primary);
  }
}
