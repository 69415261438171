.ant-btn {
  @include pxRem(border-radius, 40);
  @include pxRem(padding, 10 70);
  @include pxRem(font-size, map-get($font-sizes, xl));
  height: auto;
  border: none;
  font-family: $font-family-utm-avo-bold;
  &[disabled] {
    opacity: 0.5;
  }
  &.btn-primary {
    box-shadow: map-get($box-shadows, primary) !important;
    color: map-get($colors, white) !important;
    background-color: map-get($colors, primary) !important;
    &.no-shadow {
      box-shadow: none !important;
    }
  }
  &.btn-default {
    color: map-get($colors, text-blue) !important;
    border: 1px solid map-get($colors, text-blue) !important;
    @include sp {
      @include pxRem(font-size, 14);
      @include pxRem(padding, 5 30);
    }
  }
  &.btn-orange {
    @include pxRem(padding, 5 45);
    color: map-get($colors, white);
    background-color: map-get($colors, orange) !important;
    @include sp {
      @include pxRem(font-size, 14);
      @include pxRem(padding, 5 30);
    }
  }
  &.btn-orange-light {
    color: map-get($colors, text-blue);
    background-color: map-get($colors, orange-light);
  }
  &.btn-outline-orange {
    border: 1.5px solid;
    color: map-get($colors, orange);
    background-color: map-get($colors, white);
  }
  &.btn-outline-primary {
    border: 1.5px solid;
    color: map-get($colors, primary);
    background-color: map-get($colors, white);
    &.btn-padding-small {
      @include pxRem(padding, 10);
      @include pxRem(font-size, 16);
      line-height: 1;
    }
  }
  &.btn-small {
    @include pxRem(padding, 5 45);
  }
  &.btn-large-padding {
    @include pxRem(padding, 10 130);
  }
}
