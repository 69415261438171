// font-family
$font-family-utm-avo: 'UTM Avo';
$font-family-utm-avo-bold: 'UTM Avo Bold';
$font-iciel-bold: 'iCiel Panton Bold';
$font-iciel-light: 'iCiel Panton Light';

$font-family-default: $font-family-utm-avo, Roboto, 'Helvetica Neue', Helvetica,
  Arial, sans-serif, serif !default;

$min-width-system: 1200;

// colors
$colors: (
  border: #d7e6f4,
  black: #212121,
  border-blue: #e7f0f9,
  category-bg: #d8f2ef,
  header-bg: #b9dcd6,
  input-bg: #f0f5fb,
  input-disabled: #f5f5f5,
  lightblue: #3ba9fa,
  msg-error: #fad1d1,
  orange: #ffaf28,
  orange-light: #ffeccc,
  primary: #103f37,
  primary-disabled: #86dfb3,
  payment-bg: #f2f4f8,
  red: #a90f0f,
  darkred: #c40b1c,
  input-error-red: #ff7875,
  text: #2e3f4d,
  text-blue: #435c70,
  text-cyan: #6f8090,
  text-gray: #a7b3be,
  qa-bg: #eff5fb,
  sidebar-bg: #ecf8f6,
  white: #fff,
  target-input-label: #8f9da9,
  target-progress-text: #d0d6dc
);

// font-size
$font-sizes: (
  3xs: 8,
  2xs: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 24,
  3xl: 30,
  4xl: 36,
  5xl: 40
);

$box-shadows: (
  form: 0px 10px 14px rgba(48, 48, 48, 0.3),
  qa-answer: 0px 4px 8px rgba(48, 48, 48, 0.2),
  primary: 0px 10px 16px rgba(50, 192, 122, 0.3),
  content: 0px 8px 30px rgba(138, 149, 158, 0.25),
  card-detail: 0px 2px 4px rgba(48, 48, 48, 0.25)
);

$breakpoints: (
  xxl: 1600px
);
