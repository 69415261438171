/* stylelint-disable no-descending-specificity */
.authen-wrapper {
  height: 100vh;

  .lp-footer {
    @include sp {
      display: none;
    }
  }

  .authenticate-layout {
    @include sp {
      height: 100%;
    }
  }
}

.lp-footer {
  position: relative;
  padding: 20px 0;
  color: #ffffff;
  background-color: #9fc2be;
  height: 22%;

  @include sp {
    padding: 35px 20px;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;

    @include sp {
      flex-direction: column;
    }

    @include pc {
      align-items: center;
      width: 80%;
      margin: 0 auto;
    }
  }

  &__header {
    @include sp {
      order: 2;
      margin-top: 40px;
    }
  }

  &__copyright,
  &__social {
    @include u-fz(14, 22, false, true);

    margin-top: 10px;
  }

  &__social-icons {
    margin-top: 12px;
  }

  &__social-icons-item {
    &:not(:first-child) {
      margin-left: 16px;
    }

    .lp-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    @include sp {
      display: flex;
      flex-direction: column;
    }

    @include pc {
      flex: 1 0 0;
      margin-left: 100px;
    }
  }

  &__extra-text {
    @include u-fz(14, 22, false, true);
  }

  &__mission {
    @include u-fz(16, 24, false, true);

    @include pc {
      margin-top: 32px;
    }
  }

  &__address {
    margin-top: 16px;
  }

  &__address-phone,
  &__address-mail {
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
      @include u-fz(14, 22, false, true);
    }

    .lp-icon {
      width: 16px;
      height: 16px;
    }
  }

  &__address-mail {
    margin-top: 8px;
  }

  &__about-company {
    @include u-fz(14, 22, false, true);
  }

  &__company-name {
    font-family: 'UTM Avo Bold', roboto, arial;
  }

  &__privacy {
    margin-left: 30px;
    a {
      color: #ffffff;
    }
  }
}
