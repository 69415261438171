.change-password-page {
  height: 100%;
  .page-content {
    @include pxRem(padding-top, 75 !important);
  }
  .change-password-form-wrapper {
    @include pxRem(max-width, 390);
    @include pxRem(border-radius, 8);
    @include pxRem(padding, 30 40 40);
    margin: 0 auto;
    position: relative;
    box-shadow: map-get($box-shadows, form);
    .profile-title {
      @include pxRem(font-size, map-get($font-sizes, xxl));
      color: map-get($colors, text-blue);
      letter-spacing: 1px;
    }
    .change-password-image {
      @include pxRem(top, -50);
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
}
