.card-detail-item {
  width: 100%;
  height: 100%;
  position: relative;
  .sub-layout-1,
  .sub-layout-2,
  .main-layout {
    @include pxRem(border-radius, 10);
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: map-get($box-shadows, card-detail);
    background-color: map-get($colors, sidebar-bg);
  }
  .sub-layout-1 {
    transform: rotate(-3deg);
    background-color: map-get($colors, header-bg);
  }
  .sub-layout-2 {
    box-shadow: map-get($box-shadows, qa-answer);
    background-color: map-get($colors, category-bg);
    transform: rotate(3deg);
  }
  .main-layout {
    @include pxRem(padding, 20);
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: map-get($colors, sidebar-bg);
    .card-detail-header {
      .card-circle {
        @include pxRem(width, 20);
        @include pxRem(height, 20);
        border: 1px solid map-get($colors, primary);
      }
      .card-name {
        @include pxRem(font-size, map-get($font-sizes, md));
        left: 50%;
        position: absolute;
        font-style: italic;
        transform: translateX(-50%);
        color: map-get($colors, text-blue);
      }
      .card-logo-wrapper {
        line-height: 1;
      }
    }
    .card-level {
      @include pxRem(right, 20);
      @include pxRem(bottom, 20);
      @include pxRem(padding, 7 20);
      @include pxRem(border-radius, 40);
      @include pxRem(font-size, map-get($font-sizes, md));
      position: absolute;
      color: map-get($colors, white);
      background-color: map-get($colors, orange);
    }
    .card-image {
      object-fit: contain;
    }
  }
  &.card-small {
    .main-layout {
      @include pxRem(padding, 6);
      padding-top: 51%;
      .card-detail-header {
        @include pxRem(top, 6);
        position: absolute;
        .card-circle {
          @include pxRem(width, 10);
          @include pxRem(height, 10);
        }
      }
      .card-level {
        display: none;
      }
    }
  }
}
