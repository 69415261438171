.dashboard-page {
  .categories-list {
    @media (min-width: map-get($breakpoints, xxl) + 1) {
      .ant-col-xl-8 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  .filter-text {
    @include pxRem(font-size, 24);
    @include pxRem(margin-left, 40);
    color: map-get($colors, primary);
    cursor: pointer;
  }
  .empty-data {
    @include pxRem(right, 300);
    bottom: 0;
    position: absolute;
    .empty-message {
      @include pxRem(border-radius, 20);
      @include pxRem(padding, 40);
      position: relative;
      transform: rotate(5deg);
      background-color: map-get($colors, header-bg);
      &::after {
        @include pxRem(bottom, -23);
        @include pxRem(right, 70);
        content: '';
        position: absolute;
        display: inline-block;
        border: #{convertPxToRem(12)} solid map-get($colors, header-bg);
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-radius: 0px 0px #{convertPxToRem(4)} 0px;
      }
    }
  }
}
