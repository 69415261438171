@each $size in $font-sizes {
  $key: nth($size, 1);
  $value: nth($size, 2);
  .text-#{$key} {
    @include pxRem(font-size, $value);
  }
}

.text-default {
  color: map-get($colors, text);
}

.text-primary {
  color: map-get($colors, primary) !important;
}

.text-lightblue {
  color: map-get($colors, lightblue);
}

.text-blue {
  color: map-get($colors, text-blue);
}

.text-cyan {
  color: map-get($colors, text-cyan);
}

.text-orange {
  color: map-get($colors, orange) !important;
}

.text-white {
  color: map-get($colors, white);
}

.text-black {
  color: map-get($colors, black);
}

.text-gray {
  color: map-get($colors, text-gray);
}

.text-red {
  color: map-get($colors, red);
}

.text-darkred {
  color: map-get($colors, darkred);
}

.text-white-blue {
  color: map-get($colors, target-input-label);
}

.text-upper {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-justfy {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-preline {
  white-space: pre-line;
}

.text-line-through {
  text-decoration: line-through;
}

.font-italic {
  font-style: italic;
}

.font-normal {
  font-family: $font-family-utm-avo;
}

.font-bold {
  font-family: $font-family-utm-avo-bold;
}

.font-iciel-bold {
  font-family: $font-iciel-bold;
}

.font-iciel-light {
  font-family: $font-iciel-light;
}
