.profile-page {
  height: 100%;
  .page-content {
    @include pxRem(padding-top, 120 !important);
  }
  .profile-form-wrapper {
    @include pxRem(max-width, 730);
    @include pxRem(border-radius, 8);
    @include pxRem(padding, 30 40 40);
    margin: 0 auto;
    position: relative;
    box-shadow: map-get($box-shadows, form);
    .profile-avatar {
      @include pxRem(top, -60);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .select-avatar-icon {
        @include pxRem(right, -10);
        bottom: 0;
        position: absolute;
      }
    }
  }
}
