.payment-page {
  .page-content-wrapper {
    @include pxRem(max-width, 970);
    margin-left: auto;
    margin-right: auto;
    .payment-cash-note {
      @include sp {
        @include pxRem(font-size, 14);
        @include pxRem(margin-top, 24);
      }
    }
    .payment-action {
      @include sp {
        @include pxRem(margin-top, 32);
      }
      .btn-pay {
        @include sp {
          @include pxRem(padding, 10 0);
          width: calc(100% - #{convertPxToRem(20)});
        }
      }
    }
  }
  .page-content {
    @include pc {
      @include pxRem(padding, 20 42);
    }
  }
  .payment-info-section {
    @include sp {
      flex-direction: column;
    }
  }
  .shipping-info-section,
  .shipping-methods-section,
  .payment-methods-list {
    @include pxRem(border-radius, 14);
    @include pxRem(padding, 20);
    background-color: map-get($colors, white);
    box-shadow: map-get($box-shadows, content);
    .section-title {
      line-height: 1;
      font-family: $font-family-utm-avo-bold;
    }
  }
  .payment-methods-list {
    @include pxRem(padding, 20 20 32);
  }
  .shipping-info-section {
    position: relative;
    .btn-shipping-info {
      font-family: $font-family-utm-avo;
      &.btn-edit-info {
        @include pxRem(top, 20);
        @include pxRem(right, 20);
        position: absolute;
        padding: 0;
        border: none;
        margin: 0;
      }
      @include pc {
        @include pxRem(top, 20);
        @include pxRem(right, 20);
        position: absolute;
      }
      @include sp {
        @include pxRem(margin, 20 auto 0);
        display: block;
      }
    }
    .shipping-info-list {
      display: flex;
      @include sp {
        flex-direction: column;
      }
      .shipping-info-item {
        display: flex;
        .shipping-info-icon {
          @include pxRem(padding-top, 2);
          flex: 0 0 #{convertPxToRem(20)};
        }
        &:not(:first-child) {
          @include pc {
            @include pxRem(margin-left, 48);
          }
          @include sp {
            @include pxRem(margin-top, 20);
          }
        }
        &:last-child {
          flex: 1 0 0;
        }
      }
    }
  }
  .shipping-methods-section {
    @include pxRem(padding, 20 20 50);
    @include sp {
      @include pxRem(padding, 20 20 70);
    }
  }
  .payment-methods-list,
  .shipping-methods-content {
    .ant-radio-group {
      width: 100%;
      &.shipping-method-radio-group {
        display: flex;
        @include sp {
          flex-direction: column;
        }
        .payment-method-item {
          flex: 1 0 0;
          &:last-child {
            @include pc {
              @include pxRem(margin-left, 28);
            }
            @include sp {
              @include pxRem(margin-top, 44);
            }
          }
        }
      }
      .payment-method-item {
        @include pxRem(padding, 14 20);
        @include pxRem(border-radius, 12);
        @include pxRem(min-height, 56);
        display: flex;
        align-items: center;
        position: relative;
        color: map-get($colors, text-blue);
        background-color: map-get($colors, input-disabled);
        @include sp {
          @include pxRem(font-size, 14);
        }
        &.ant-radio-wrapper-checked {
          background-color: map-get($colors, category-bg);
        }
        .option-note {
          left: 0;
          top: calc(100% + #{convertPxToRem(4)});
          position: absolute;
          font-family: $font-family-utm-avo;
          color: map-get($colors, text-gray);
        }
        .ant-radio {
          @include pxRem(top, 16);
          position: absolute;
          & + span {
            @include pxRem(margin-left, 36);
            padding: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            @include pc {
              align-items: center;
            }
            @include sp {
              flex-direction: column;
            }
            img {
              @include sp {
                @include pxRem(margin-top, 4);
              }
            }
          }
        }
      }
    }
  }

  .payment-methods {
    @include pxRem(flex-basis, 560);
    @include sp {
      @include pxRem(margin-top, 24);
      flex-basis: unset;
      width: 100%;
    }
  }
  .payment-total {
    @include pxRem(padding, 20 24);
    @include pxRem(flex-basis, 380);
    @include pxRem(border-radius, 14);
    background-color: map-get($colors, category-bg);
    @include sp {
      @include pxRem(padding, 20);
      margin-left: 0;
      width: 100%;
      order: -1;
      flex-basis: unset;
    }
    .payment-total-detail {
      .object-name {
        @include sp {
          @include pxRem(font-size, map-get($font-sizes, lg));
        }
      }
      .object-number-card {
        @include sp {
          @include pxRem(font-size, map-get($font-sizes, md));
        }
      }
    }
    .payment-discount-apply {
      @include pxRem(margin-top, 20);
      @include sp {
        @include pxRem(margin-top, 16);
      }
      .input-discount {
        @include pxRem(padding, 5 16);
        @include pxRem(border-radius, 30);
        background-color: map-get($colors, white);
        border: 1px solid map-get($colors, target-input-label);
        .ant-input-suffix {
          @include pxRem(right, 8);
        }
        .ant-input {
          padding: 0;
          border-radius: 0;
          background-color: map-get($colors, white);
        }
      }
      .btn-apply-discount {
        @include pxRem(font-size, 14);
        @include pxRem(padding, 8 20);
      }
    }
    .payment-total-price {
      &::before {
        @include pxRem(margin, 16 0);
        content: '';
        width: 100%;
        display: block;
        border-top: 1px solid map-get($colors, text-gray);

        @include sp {
          @include pxRem(margin, 12 0);
        }
      }
      .total-text {
        @include sp {
          @include pxRem(font-size, map-get($font-sizes, xl));
        }
      }
      .object-total-price {
        @include sp {
          @include pxRem(font-size, map-get($font-sizes, xxl));
        }
      }
    }
  }
}
