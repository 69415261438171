.category-item {
  @include pxRem(padding, 15 20);
  @include pxRem(height, 200);
  @include pxRem(border-radius, 20);
  position: relative;
  background-color: map-get($colors, category-bg);
  @include sp {
    @include pxRem(padding, 15);
    @include pxRem(height, 180);
  }
  &.show-price {
    @include pc {
      @include pxRem(height, 220);
    }
    @include sp {
      @include pxRem(height, 205);
    }
  }
  .category-item-left,
  .category-item-right {
    z-index: 2;
    .ant-progress-inner {
      @include pxRem(width, 70 !important);
      @include pxRem(height, 70 !important);
    }
    .ant-progress-text {
      @include pxRem(font-size, map-get($font-sizes, md));
    }
  }
  .category-item-left {
    flex: 1;
    .category-info {
      &.has-padding {
        @include pc {
          @include pxRem(padding-right, 116);
        }
        @include sp {
          @include pxRem(padding-right, 96);
        }
      }
    }
    .category-name {
      @include line-clamp(2);
      @include sp {
        @include pxRem(font-size, 15);
      }
    }
    .category-question-number {
      @include sp {
        @include pxRem(font-size, 13);
      }
    }
    .btn-group {
      .btn-action {
        border: none !important;
        @include pc {
          @include pxRem(padding, 8 30);
          @include pxRem(font-size, 14);
        }
        &.btn-preview {
          @include pxRem(margin-left, 12);
        }
      }
    }
  }
  .category-item-right {
    position: relative;

    .discount-price {
      @include sp {
        @include pxRem(font-size, 16);
      }
    }
    .real-price {
      * {
        @include sp {
          @include pxRem(font-size, 13);
        }
      }
    }
    .category-image {
      position: absolute;
      top: 0;
      right: 0;

      &.absolute-bottom {
        bottom: 0;
        top: unset;
      }

      @include sp {
        @include pxRem(width, 80);
        @include pxRem(height, 80);
      }
    }
  }
  .intersect-icon {
    left: 0;
    bottom: 0;
    position: absolute;
    @include sp {
      @include pxRem(width, 100);
    }
  }
}
