.analytics-page {
  .page-content {
    &.has-page-title {
      padding-top: 15px !important;
    }
  }
  .analytics-wrapper {
    width: 100%;
    .chart-wrapper {
      @include pxRem(padding, 30);
      @include pxRem(margin-right, 30);
      width: 100%;
      box-shadow: 0px 8px 30px rgba(138, 149, 158, 0.25);
      border-radius: 14px;
      .chart-header {
        @include pxRem(padding-bottom, 25);
        width: 100%;
        .chart-title {
          @include pxRem(line-height, 30);
        }
        .chart-filter {
          .filter-button {
            @include pxRem(font-size, map-get($font-sizes, md));
            @include pxRem(width, 100);
            @include pxRem(height, 36);
            @include pxRem(font-size, 16);
            @include pxRem(line-height, 23);
            padding: 0;
            font-weight: bold;
          }
        }
      }
      .chart-content {
        @include pxRem(height, 295);
        width: 100%;
        position: relative;
        .no-data-img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 999;
        }
        .chart {
          @include pxRem(padding-bottom, 25);
          width: 100%;
        }
      }
      .chart-select-year {
        width: 100%;
        position: relative;
        .chart-year-text {
          @include pxRem(margin, 0 30);
          @include pxRem(line-height, 35);
        }
        .current-button {
          @include pxRem(width, 40);
          @include pxRem(height, 40);
          position: absolute;
          right: 0;
        }
      }
    }
    .progress-wrapper {
      @include pxRem(padding, 30 40 60);
      @include pxRem(width, 350);
      @include pxRem(border-radius, 14);
      box-shadow: 0px 8px 30px rgba(138, 149, 158, 0.25);
      align-self: stretch;
      .progress-title {
        @include pxRem(line-height, 30);
      }
      .progress-info {
        background-color: #d8f2ef;
        @include pxRem(border-radius, 999);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .progress-info-text {
          @include pxRem(line-height, 30);
          @include pxRem(padding-bottom, 12);
        }
        .progress-sub-info-text {
          @include pxRem(line-height, 20);
          color: #8f9da9;
        }
      }
      .ant-progress-text {
        @include pxRem(height, 160);
        @include pxRem(width, 160);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
