@for $var from 0 to 11 {
  $unit: 0.5rem * $var;
  .pt-#{$var} {
    padding-top: $unit;
  }
  .pb-#{$var} {
    padding-bottom: $unit;
  }
  .pl-#{$var} {
    padding-left: $unit;
  }
  .pr-#{$var} {
    padding-right: $unit;
  }
  .mt-#{$var} {
    margin-top: $unit;
  }
  .mb-#{$var} {
    margin-bottom: $unit;
  }
  .ml-#{$var} {
    margin-left: $unit;
  }
  .mr-#{$var} {
    margin-right: $unit;
  }
  .mx-#{$var} {
    margin-right: $unit;
    margin-left: $unit;
  }
  .my-#{$var} {
    margin-top: $unit;
    margin-bottom: $unit;
  }
  .px-#{$var} {
    padding-right: $unit;
    padding-left: $unit;
  }
  .py-#{$var} {
    padding-top: $unit;
    padding-bottom: $unit;
  }
}
