.authenticate-layout {
  @include pxRem(padding, 15);
  overflow: auto;
  height: 78%;
  background: linear-gradient(
      90deg,
      rgba(16, 63, 55, 0.25) 34.16%,
      rgba(255, 255, 255, 0.5) 100%
    ),
    url('../../assets/bg/authenticate-bg.jpg') no-repeat;
  background-size: cover;
  @include sp {
    @include pxRem(padding, 20);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .app-logo {
    @include pxRem(top, 15);
    @include pxRem(left, 15);
    position: absolute;
    @include sp {
      position: initial;
    }
  }
  .authenticate-form-wrapper {
    @include pxRem(margin-left, 276);
    @include pxRem(border-radius, 8);
    @include pxRem(max-width, 390);
    flex: 0 0 convertPxToRem(390);
    text-align: center;
    background-color: map-get($colors, white);
    box-shadow: map-get($box-shadows, form);
    overflow: auto;
    max-height: 95%;
    .authenticate-form {
      @include pxRem(padding, 30 40 40);
      overflow: auto;
      @include sp {
        @include pxRem(padding, 30 !important);
      }
    }
    .text-signin,
    .text-signup {
      @include sp {
        display: block;
        margin-left: 0;
      }
    }
    @include sp {
      @include pxRem(margin-top, 50);
      margin-left: 0;
      width: 100%;
      max-height: calc(95% - 110px);
      flex: 0 0 auto;
    }
  }
  .auth-title {
    @include pxRem(font-size, map-get($font-sizes, xxl));
    color: map-get($colors, text-blue);
    font-family: $font-family-utm-avo-bold;
    letter-spacing: 1px;
  }
  .nurse-image {
    @include pxRem(margin-bottom, -15);
    @include pxRem(margin-left, 100);
    align-self: flex-end;
    height: 580px;
    @include sp {
      display: none;
    }
  }
}
