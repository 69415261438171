.bg-white {
  background-color: map-get($colors, white);
}

.bg-orange {
  background-color: map-get($colors, orange);
}

.bg-primary {
  background-color: map-get($colors, primary);
}
