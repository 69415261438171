.qa-category-list {
  .qa-category-item {
    @include pxRem(border-radius, 20 !important);
    background-color: map-get($colors, qa-bg);
    .ant-collapse-header {
      @include pxRem(padding, 20 30 5 !important);
    }
    .ant-progress-inner {
      @include pxRem(border-radius, 40);
    }
    .ant-progress-bg,
    .ant-progress-success-bg {
      @include pxRem(height, 4 !important);
    }
    .ant-collapse-content-box {
      @include pxRem(padding, 0 30 20 !important);
      .qa-result {
        &::before {
          @include pxRem(width, 20);
          @include pxRem(height, 20);
          @include pxRem(margin-right, 20);
          content: '';
          border-radius: 50%;
          display: inline-block;
          background-color: map-get($colors, text-gray);
        }
        &.correct-answer {
          &::before {
            background-color: map-get($colors, primary);
          }
        }
        &.incorrect-answer {
          &::before {
            background-color: map-get($colors, red);
          }
        }
      }
    }
    &.ant-collapse-item-active {
      background-color: map-get($colors, category-bg);
    }
  }
}
