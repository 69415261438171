.progress-bar {
  top: 0;
  width: 100%;
  position: fixed;
  overflow: hidden;
  span {
    display: block;
  }
  .progress {
    @include pxRem(padding, 3);
    animation: progress 3s infinite linear;
    // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
    background: map-get($colors, primary);
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
