.content-layout {
  .page-wrapper {
    height: 100%;
  }
  .page-title {
    @include pxRem(padding, 0 42);
    @include pxRem(font-size, 30);
    @include pxRem(height, 100);
    display: flex;
    align-items: center;
    color: map-get($colors, text-blue);
  }
  .page-content {
    @include pxRem(padding, 0 42 30);
    height: 100%;
    overflow: auto;
    position: relative;
    @include sp {
      @include pxRem(padding, 0 20 20);
      flex-direction: column;
      display: flex;
    }
    &.has-page-title {
      @include pc {
        padding-top: 0 !important;
        height: calc(100% - #{convertPxToRem(100)});
      }
    }
    &.has-page-header {
      padding-top: 0 !important;
      height: calc(100% - #{convertPxToRem(60)});
    }
    &.full-height {
      @include pc {
        @include pxRem(padding-top, 40);
      }
    }
    .loading-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      position: absolute;
      background-color: map-get($colors, white);
      .loading-icon {
        @include pxRem(width, 200);
        top: 30%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
    .search-no-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
