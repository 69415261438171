.ant-radio-group {
  .ant-radio-wrapper {
    @include pxRem(font-size, map-get($font-sizes, md));
    margin-right: 0;
    .ant-radio {
      & + span {
        white-space: initial;
      }
      .ant-radio-inner {
        @include pxRem(width, 24);
        @include pxRem(height, 24);
        border-color: map-get($colors, text-blue) !important;
        &::after {
          @include pxRem(top, 4);
          @include pxRem(left, 4);
          @include pxRem(width, 14);
          @include pxRem(height, 14);
          background-color: map-get($colors, text-blue);
        }
      }
      &.ant-radio-disabled {
        .ant-radio-inner {
          opacity: 0.2;
        }
      }
    }
  }
}
