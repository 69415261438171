.ant-tooltip {
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content {
    background-color: map-get($colors, white);
  }

  .ant-tooltip-inner {
    @include pxRem(padding, 10);
    @include pxRem(border-radius, 12);
    @include pxRem(font-size, map-get($font-sizes, sm));
    color: map-get($colors, text-blue);
  }
}
