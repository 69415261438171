.payment-options-page {
  .page-content-wrapper {
    @include pxRem(max-width, 1144);
    margin-left: auto;
    margin-right: auto;
    @include sp {
      @include pxRem(margin-top, 20);
      flex-direction: column;
    }
  }
  .page-content {
    @include sp {
      @include pxRem(padding, 0 20 60);
    }
  }
  .payment-combo-section,
  .payment-normal-section,
  .payment-paper-section {
    @include pxRem(border-radius, 20);
    position: relative;
    background-color: map-get($colors, category-bg);
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    @include pc {
      @include pxRem(padding, 10 10 52);
    }
    @include sp {
      @include pxRem(padding, 10 10 45);
    }
    .section-heading {
      @include pxRem(border-radius, 14);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: map-get($colors, white);
      @include pc {
        @include pxRem(padding, 0 28);
        @include pxRem(min-height, 175);
      }
      @include sp {
        @include pxRem(padding, 12 32);
        @include pxRem(min-height, 170);
      }
      .heading-text,
      .heading-discount {
        @include sp {
          @include pxRem(font-size, 14);
        }
      }
      .heading-description {
        @include sp {
          @include pxRem(font-size, 13);
        }
      }
      .heading-price {
        @include sp {
          @include pxRem(font-size, 18);
        }
      }
    }
    .section-content {
      @include pc {
        flex: 1 0 0;
        display: flex;
        align-items: center;
      }
      @include sp {
        @include pxRem(margin-top, 20);
      }
    }
    .btn-buy {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(50%);
      @include sp {
        @include pxRem(padding, 8 48);
      }
    }
  }

  .payment-paper-section,
  .payment-combo-section {
    @include pc {
      @include pxRem(margin-left, 28);
    }
    @include sp {
      @include pxRem(margin-top, 48);
    }
    .section-content {
      @include pc {
        @include pxRem(padding, 0 12);
      }
      @include sp {
        @include pxRem(padding, 0 8);
      }
      .section-image {
        @include pxRem(border-radius, 10);
      }
    }
  }

  .payment-normal-section {
    .section-content {
      @include pc {
        @include pxRem(padding, 0 16);
      }
      @include sp {
        @include pxRem(padding, 0 12);
      }
      &.package-content {
        @include pc {
          @include pxRem(padding, 0 10);
        }
        @include sp {
          @include pxRem(padding, 0 16);
        }
      }
      .card-preview-list {
        @include pc {
          flex: 1 0 0;
        }
      }
      .category-image-list {
        @include pxRem(padding, 16 28);
        @include pxRem(border-radius, 12);
        background-color: map-get($colors, white);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp {
          @include pxRem(padding, 32 24);
        }
      }
    }
  }
}
