.ant-input-affix-wrapper > input.ant-input,
.ant-input {
  @include pxRem(padding, 10 20);
  @include pxRem(border-radius, 12);
  @include pxRem(font-size, map-get($font-sizes, md));
  @include pxRem(line-height, 30);
  color: map-get($colors, text-blue);
  background-color: map-get($colors, input-bg);
  border: none;
  @include sp {
    @include pxRem(padding, 7 16);
  }
  &::placeholder {
    color: map-get($colors, text-gray);
  }
}
.ant-input-affix-wrapper {
  @include pxRem(border-radius, 12);
  border: none;
  padding: 0;
  & > input.ant-input {
    @include pxRem(padding-right, 50);
  }
  .ant-input-suffix {
    @include pxRem(right, 20);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}

.ant-form-item-has-error {
  .ant-input {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    border-color: map-get($colors, input-error-red);
  }
}
