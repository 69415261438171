.policy-wrapper {
  .policy-header {
    height: 80px;
    background-color: #b9dcd6;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 40px;
  }

  .policy-content {
    padding: 35px 20px;
    text-align: justify;

    @include pc {
      width: calc(100% - 48px);
      max-width: Max(calc(100% / 3 * 2), 860px);
      padding: 40px 0;
      margin: 0 auto;
    }

    strong {
      font-family: 'UTM Avo Bold', roboto, arial;
      font-weight: bold;

      + p {
        margin-top: 10px;
      }
    }

    ol,
    ul {
      list-style: inside;

      li {
        margin-top: 5px;
      }

      + ul,
      + ol {
        margin-top: 10px;
      }
    }

    ol {
      list-style-type: upper-roman;
      ol {
        list-style-type: decimal;
        margin-left: 10px;
      }

      ul {
        padding-left: 20px;
      }
    }

    ul {
      + p {
        margin-top: 5px;
      }
    }
  }
}
