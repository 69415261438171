.carousel-detail {
  .current-carousel-index {
    @include pxRem(left, -140);
    line-height: 1;
    position: absolute;
  }
}
.btn-carousel-control {
  @include pxRem(width, 50);
  @include pxRem(height, 50);
  padding: 0;
  &[disabled] {
    background-color: map-get($colors, category-bg) !important;
    path {
      fill: map-get($colors, primary);
    }
  }
  .anticon {
    @include pxRem(font-size, map-get($font-sizes, 3xl));
    display: flex;
  }
}
