.main-page-wrapper {
  @include sp {
    display: none;
  }
}
.coming-soon-section {
  display: none;
  @include sp {
    @include pxRem(padding, 0 20);
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-color: map-get($colors, category-bg);
  }
  .coming-soon-content {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    .logo {
      text-align: center;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .coming-nurse {
      @include pxRem(margin-top, 40);
      display: flex;
      flex-direction: column;
      .message {
        @include pxRem(height, 130);
        @include pxRem(border-radius, 20);
        @include pxRem(padding, 10);
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: map-get($colors, white);
        &::after {
          @include pxRem(bottom, -23);
          @include pxRem(left, 60);
          position: absolute;
          content: '';
          border: 12px solid white;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-radius: 0 0 4px 0;
        }
        p {
          margin-top: 4px;
          font-family: $font-family-utm-avo-bold;
          font-size: 18px;
          color: map-get($colors, primary);
        }
      }
      .nurse {
        @include pxRem(margin-top, 35);
      }
    }
  }
}
