.tutorial-page {
  .page-content {
    @include pxRem(padding-top, 55 !important);
  }
  .tutorial-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .tutorial-carousel {
      .tutorial-1 {
        @include pxRem(max-width, 750 !important);
      }
      .tutorial-2 {
        @include pxRem(margin-top, 65);
        @include pxRem(max-width, 830 !important);
      }
    }
    .tutorial-actions {
      position: relative;
      .button-group {
        right: 0;
        position: absolute;
      }
    }
  }
}
