.qa-questions-page {
  .questions-carousel {
    @include pxRem(width, 700);
    @include pxRem(height, 415);
    @include pxRem(padding, 80 120 20);
    position: relative;
    background: url('../../assets/bg/qa-question-bg.png') no-repeat;
    .ant-carousel {
      height: 100%;
      .slick-slider {
        height: 100%;
      }
      .slick-list {
        height: 100%;
      }
      .slick-track {
        height: 100%;
      }
      .slick-current {
        height: 100%;
        overflow: auto;
      }
    }
    .answer-item-list-wrap {
      @include pxRem(min-width, 280);
      .answer-item-list {
        min-width: inherit;
        flex-wrap: wrap;
        width: auto;
        .answer-item {
          @include pxRem(padding, 5);
          min-width: inherit;
          width: 96%;
          border-radius: 999px;
          color: map-get($colors, text-blue);
          background-color: map-get($colors, white);
          box-shadow: map-get($box-shadows, qa-answer);
          position: relative;
          .answer-text {
            @include pxRem(height, 30);
            @include pxRem(line-height, 30);
            flex: 0 0 30px;
            background-color: map-get($colors, primary);
          }
          &.active {
            color: map-get($colors, white);
            background-color: map-get($colors, primary);
            .answer-text {
              color: map-get($colors, primary);
              background-color: map-get($colors, white);
            }
          }
          &.incorrect {
            color: map-get($colors, white);
            background-color: map-get($colors, red);
            .answer-text {
              color: map-get($colors, red);
              background-color: map-get($colors, white);
            }
          }
          .result-icon {
            @include pxRem(right, 10);
            position: absolute;
          }
        }
      }
    }
  }
}
