.category-page {
  .ant-tabs {
    height: 100%;
    .ant-tabs-content-holder {
      overflow: auto;
      height: 100%;
      .ant-tabs-content {
        height: 100%;
      }
    }
    .ant-tabs-nav {
      @include pxRem(padding, 0 42);
      @include pxRem(height, 100);
      margin: 0;
      @include sp {
        @include pxRem(padding, 0 20);
        @include pxRem(height, 80);
      }
      .ant-tabs-nav-wrap {
        &::before,
        &::after {
          box-shadow: none;
        }
      }
      .ant-tabs-nav-operations {
        display: none;
      }
      &::before {
        border-bottom: none;
      }
      .ant-tabs-tab {
        @include pxRem(font-size, 30);
        color: map-get($colors, text-gray);
        font-family: $font-family-utm-avo-bold;
        @include sp {
          @include pxRem(font-size, 20);
          @include pxRem(margin, 0 28 0 0 !important);
        }
        .ant-tabs-tab-btn:active {
          color: map-get($colors, text-blue) !important;
        }
        .ant-tabs-tab-btn:focus {
          color: map-get($colors, text-blue) !important;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: map-get($colors, text-blue) !important;
          }
        }
      }
      .ant-tabs-ink-bar {
        background: none;
      }
    }
  }
  .categories-list {
    @media (min-width: map-get($breakpoints, xxl) + 1) {
      .ant-col-xl-8 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}
