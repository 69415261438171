/**
  * RESPONSIVE AREA
  */
$breakpoint-sp: 640px;
$breakpoint-small-sp: 320px;

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin pc() {
  @media (min-width: $breakpoint-sp + 1) {
    @content;
  }
}

@mixin sp() {
  @media (max-width: $breakpoint-sp) {
    @content;
  }
}

@mixin small-sp() {
  @media (max-width: $breakpoint-small-sp) {
    @content;
  }
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// === CONVERT PXREM ===
@mixin pxRem($property, $values) {
  // Create a couple of empty lists as output buffers:
  $px-values: ();
  $rem-values: ();

  // Ensure $values is a list.
  @if type-of($values) != 'list' {
    $values: join((), $values);
  }

  // Loop through the $values list:
  @each $value in $values {
    @if type-of($value) == number and (unitless($value) or unit($value) == px) {
      $px-values: join($px-values, 0px + ($value));
      $rem-values: join($rem-values, convertPxToRem($value));
    } @else {
      // no conversion:
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
  }

  #{$property}: $px-values;

  // don't add media query if it's not needed:
  @if $px-values != $rem-values {
    // @media only all {
    #{$property}: $rem-values;
    // }
  }
}

@mixin line-clamp($row) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

@mixin u-fz(
  $fontSizePx: false,
  $lineHeightPx: false,
  $letterSpacingPx: false,
  $cutLineSpace: false
) {
  @if ($fontSizePx) {
    font-size: $fontSizePx + px;
  }

  @if ($lineHeightPx) {
    line-height: ($lineHeightPx / $fontSizePx);
  }

  @if ($letterSpacingPx) {
    letter-spacing: ($letterSpacingPx / $fontSizePx) + em;
  }

  @if ($cutLineSpace) {
    &::before,
    &::after {
      display: block;
      overflow: hidden;
      content: '';
    }

    $_marginVal: ($lineHeightPx - $fontSizePx) * -0.5;

    &::before {
      margin-bottom: $_marginVal + px;
    }

    &::after {
      margin-top: $_marginVal + px;
    }
  }
}
