* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body {
  @include font-smoothing(antialiased);
  @include pxRem(font-size, map-get($font-sizes, md));
  color: map-get($colors, text-blue);
  background-color: map-get($colors, white);
  font-family: $font-family-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.35;
}

a {
  color: map-get($colors, text-blue);
  &:hover {
    color: inherit;
  }
}

button {
  outline: none;
}
