@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function convertRootPxToEm($value) {
  // check for null:
  @if ($value == null) {
    @return $value;
  }
  // assume em:
  @if (unitless($value) or unit($value) == em) {
    @return 0em + $value;
  } @else {
    @return 0em + strip-units($value) / 16;
  }
}

@function convertPxToRem($px) {
  @return 0rem + strip-units($px) / 16;
}
