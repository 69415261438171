.package-item {
  @include pxRem(padding, 25 30);
  @include pxRem(height, 290);
  @include pxRem(border-radius, 20);
  position: relative;
  background-color: map-get($colors, category-bg);
  @include sp {
    @include pxRem(height, 200);
    @include pxRem(padding, 15);
  }
  .package-item-content {
    @include pxRem(padding-bottom, 10);
    z-index: 2;
    flex: 1;
    @include sp {
      padding-bottom: 0;
    }
    .category-name-list {
      @include line-clamp(3);
    }
    .package-item-name {
      @include line-clamp(2);
    }
    .package-item-name {
      @include sp {
        @include pxRem(font-size, 15);
      }
    }
    .category-numbers {
      @include sp {
        @include pxRem(font-size, 13);
      }
    }
    .category-name-list {
      @include sp {
        @include pxRem(font-size, 14);
        @include pxRem(margin-top, 12);
      }
    }
  }
  .package-item-info {
    .discount-price {
      @include sp {
        @include pxRem(font-size, 16);
      }
    }
    .real-price {
      * {
        @include sp {
          @include pxRem(font-size, 13);
        }
      }
    }
    .package-image {
      @include sp {
        @include pxRem(width, 100);
      }
    }
  }
  .intersect-icon {
    left: 0;
    bottom: 0;
    position: absolute;
    @include sp {
      @include pxRem(width, 100);
    }
  }
}
