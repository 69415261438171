.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.border-circle {
  border-radius: 50% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-none {
  pointer-events: none;
}

.is-hidden {
  height: 0;
  display: block;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}
