.nurse-inform {
  @include pxRem(right, 40);
  bottom: 0;
  position: fixed;
  .inform-message {
    @include pxRem(padding, 40 25);
    @include pxRem(max-width, 400);
    @include pxRem(border-radius, 20);
    position: relative;
    transform: rotate(5deg);
    background-color: map-get($colors, category-bg);
    &::after {
      @include pxRem(bottom, -23);
      @include pxRem(right, 70);
      content: '';
      position: absolute;
      display: inline-block;
      border: #{convertPxToRem(12)} solid map-get($colors, category-bg);
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-radius: 0px 0px #{convertPxToRem(4)} 0px;
    }
  }
  &.empty-data-inform {
    @include pxRem(right, 300);
    .inform-message {
      max-width: initial;
    }
  }
  &.tutorial-inform {
    @include pxRem(left, 40);
    @include pxRem(width, 230);
    transform: rotateY(180deg);
    .inform-message {
      @include pxRem(padding, 20 12);
      transform: rotateY(180deg) rotateZ(-5deg);
      &::after {
        @include pxRem(left, 70);
        right: unset;
        transform: rotateY(180deg);
      }
    }
  }
  &.feedback-des-inform {
    @include pxRem(right, 60);
    .inform-message {
      @include pxRem(width, 390);
      @include pxRem(font-size, 20);
      @include pxRem(line-height, 30);
    }
  }
}
