@import 'authentication';
@import 'content';
@import 'header';
@import 'sidebar';
@import 'footer';

.ant-layout,
.ant-layout-content {
  height: 100%;
}

.fetching-data-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    @include sp {
      @include pxRem(width, 200);
    }
  }
}
