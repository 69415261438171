.page-finished {
  .page-content {
    @include sp {
      justify-content: space-between;
      padding-bottom: 0;
    }
  }
  .finished-content {
    @include pxRem(max-width, 600);
    @include pxRem(padding, 45 40);
    margin: 0 auto;
    @include pxRem(margin-top, 75);
    @include pxRem(border-radius, 14);
    @extend .d-centered;
    @extend .flex-column;
    background-color: map-get($colors, sidebar-bg);
    @include sp {
      @include pxRem(margin, 20 0 0);
      @include pxRem(padding, 24);
    }
    &.finished-payment {
      @include pc {
        @include pxRem(padding, 45 80);
      }
    }
    .btn-group {
      .ant-btn {
        @include pxRem(padding, 10);
        flex: 1;
      }
    }
    .main-message {
      @include sp {
        @include pxRem(font-size, map-get($font-sizes, xxl));
      }
    }
    .sub-message {
      @include sp {
        @include pxRem(font-size, map-get($font-sizes, lg));
      }
    }
    .finish-image {
      @include sp {
        @include pxRem(width, 100);
      }
      &.cash-payment-image {
        @include sp {
          @include pxRem(width, 176);
        }
      }
    }
    .finish-button {
      @include sp {
        @include pxRem(font-size, map-get($font-sizes, lg));
        @include pxRem(padding, 10 0);
        @include pxRem(margin-top, 24);
        width: 100%;
      }
    }
  }
  .nurse-inform {
    @include sp {
      position: relative;
      right: unset;
    }
  }
}
